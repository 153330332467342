<template>
  <div id="rules">
    <Menu :menu-items="menuItems" />
    <div v-if="bingoType" class="content">
      <div>
        <div id="tasks_grid" class="block">
          <h3>Сетка заданий</h3>
          <div class="results_grid">
            <div
              v-for="task in tasksGrid[bingoType]"
              :key="task.id"
              :class="[
                'results_cell',
                'link',
                'text_link',
                `score_${scores[bingoType][task.id]}` || ' ',
                selectedCell === task.id ? 'selected' : ''
              ]"
              @click="selectTask(task.id)"
            >
              {{ task.id }}
            </div>
          </div>
        </div>
        <div class="block">
          <h3 class="header">
            <span>Легенда</span>
            <div class="link">
              <span v-if="showLegend" @click="foldLegend()">
                ↑
              </span>
              <span v-if="!showLegend" @click="unfoldLegend()">
                ↓
              </span>
            </div>
          </h3>
          <div v-if="showLegend" class="results_grid">
            <div class="results_cell score_2x">2x</div>
            <div class="results_cell_description">
              Все задания в ряду/колонке, не считая самых крайних, которые
              относятся к диагоналям, должны быть выполнены для получения
              множителя
            </div>
            <div class="results_cell score_4x">4x</div>
            <div class="results_cell_description">
              Все задания по диагонали должны быть выполнены для получения
              множителя
            </div>
            <div class="results_cell score_3x">3x</div>
            <div class="results_cell_description">
              Творческие задания
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="block">
          <h3 class="header">
            <span>Общие правила</span>
            <div class="link">
              <span v-if="showCommonRules" @click="foldCommonRules()">
                ↑
              </span>
              <span v-if="!showCommonRules" @click="unfoldCommonRules()">
                ↓
              </span>
            </div>
          </h3>
          <div v-if="showCommonRules">
            <div
              v-for="(rule, index) in commonRules[bingoType]"
              :key="index"
              class="task_description"
            >
              <div>{{ index + 1 }}.</div>
              <div v-html="rule"></div>
            </div>
          </div>
        </div>
        <div id="tasks_description" class="block">
          <h3>Задания</h3>
          <div class="tasks">
            <div
              v-for="(group, index) in tasks[bingoType]"
              :key="`group_${index}`"
            >
              <h4>{{ group.name }}:</h4>
              <div
                v-for="task in group.tasks"
                :id="`task_description_${task.id}`"
                :key="task.id"
                :class="[
                  'task_description',
                  'link',
                  selectedCell === task.id ? 'selected' : ''
                ]"
                @click="selectedCell = task.id"
              >
                <div>{{ task.id }}:</div>
                <div>
                  <div class="text_link">{{ task.short_name }}</div>
                  <div>{{ task.description }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Rules",
  components: { Menu },
  props: {
    bingoType: {
      type: String,
      required: false,
      default: null
    }
  },
  data: function() {
    return {
      showLegend: true,
      showCommonRules: true,
      menuItems: [
        { id: "sailing", name: "Морские", link: "/rules/sailing/" },
        { id: "dungeon", name: "Подземные", link: "/rules/dungeon/" }
      ],
      commonRules: {
        dungeon: [
          "Срок проведения конкурса с 12 сентября 00:00 МСК по 19 сентября 23:59 МСК. Принимаются только те логи, которые начаты в этот временной интервал. Форма для подачи логов закрывается 20 сентября в 23:59 МСК.",
          "Каждый поход должен быть успешен (в подземелье выход через сокровищницу или кладовую живым участником).",
          "Одним логом можно закрыть любое количество заданий, за исключением творческих, они не совместимы друг с другом. То есть, одно творческое задание на один лог.",
          "Все задания одноразовые, выполняются в рамках одного данжа.",
          "Смена типа подземелья в тайной комнате позволит подать лог на оба задания по типам.",
          "В каждом задании можно получить дополнительные 0.5 балла, если в логе содержится подписанный поздравительный глас от участника.",
          "Оргкомитет не поддерживает неспортивное поведение участников по отношению друг к другу. Любые явные попытки одного участника так или иначе помешать другому (например, отъем руля в данже) приведут к тому, что для агрессора участие в конкурсе будет закончено, а все его результаты обнулены.",
          "Участников много, логов еще больше, а проверяющих мало. Поэтому если где-то что-то пошло не так, кому-то что-то не засчитали или засчитали неправильно, лог не появился в предварительных результатах или что угодно еще - пожалуйста пишите сразу в личку <a href='https://godville.net/gods/Shes'>Shes</a>, не ждите до последнего, перепроверить на ходу быстрее и проще, чем потом устраивать разбирательства.",
          "Просьба в комментариях к логу писать дополнительную информацию - номера шагов, на которых выполнено задание, угаданные имена и проч."
        ],
        sailing: [
          "Срок проведения конкурса с 12 сентября 00:00 МСК по 19 сентября 23:59 МСК. Принимаются только те логи, которые начаты в этот временной интервал. Форма для подачи логов закрывается 20 сентября в 23:59 МСК.",
          'Каждое море должно быть успешным (участник с кладом или ящиком по условию должен выйти своим ходом через край или в порт), к заданиям из группы "Встретить и победить рыбу" и "Попасть в море с типом" это тоже относится. То есть, по умолчанию нужно выйти с кладом и только в задании про ящик – с ящиком.',
          "Карта секторов для тех заданий, где это принципиально – <a href='https://picabox.ru/pic/29974855'>тут</a>",
          "Клад в море - это мешок или самец/самка, взятые с острова или финальной рыбы. Ящик - это ларец, который выпадает с некладовой рыбы или обнаруживается на острове.",
          "Одним логом можно закрыть любое количество заданий, за исключением творческих, они не совместимы друг с другом. То есть, одно творческое задание на один лог.",
          "Все задания одноразовые, выполняются в рамках одного моря.",
          "В каждом задании можно получить дополнительные 0.5 балла, если в логе содержится подписанный поздравительный глас от участника.",
          "Оргкомитет не поддерживает неспортивное поведение участников по отношению друг к другу. Любые явные попытки одного участника так или иначе помешать другому (например, пиратство) приведут к тому, что для агрессора участие в конкурсе будет закончено, а все его результаты обнулены.",
          "Участников много, логов еще больше, а проверяющих мало. Поэтому если где-то что-то пошло не так, кому-то что-то не засчитали или засчитали неправильно, лог не появился в предварительных результатах или что угодно еще - пожалуйста пишите сразу в личку <a href='https://godville.net/gods/Shes'>Shes</a>, не ждите до последнего, перепроверить на ходу быстрее и проще, чем потом устраивать разбирательства.",
          "Просьба в комментариях к логу писать дополнительную информацию - номера шагов, на которых выполнено задание, угаданные имена и проч."
        ]
      },
      selectedCell: null,
      scores: {
        dungeon: {
          // top-left to bottom-right
          "11": "4x",
          "1": "4x",
          "5": "4x",
          "13": "4x",
          "15": "4x",
          "38": "4x",
          "9": "4x",
          // top-right to bottom-left
          "14": "4x",
          "4": "4x",
          "19": "4x",
          "6": "4x",
          "45": "4x",
          "27": "4x",
          // top
          "32": "2x",
          "37": "2x",
          "2": "2x",
          "36": "2x",
          "46": "2x",
          // bottom
          "42": "2x",
          "10": "2x",
          "25": "2x",
          "39": "2x",
          "29": "2x",
          // left
          "17": "2x",
          "48": "2x",
          "7": "2x",
          "43": "2x",
          "34": "2x",
          // right
          "23": "2x",
          "44": "2x",
          "35": "2x",
          "28": "2x",
          "18": "2x",
          // creative
          "30": "3x",
          "31": "3x",
          "22": "3x"
        },
        sailing: {
          // top-left to bottom-right
          "2": "4x",
          "49": "4x",
          "16": "4x",
          "31": "4x",
          "11": "4x",
          "32": "4x",
          "25": "4x",
          // top-right to bottom-left
          "27": "4x",
          "22": "4x",
          "10": "4x",
          "48": "4x",
          "43": "4x",
          "28": "4x",
          // top
          "30": "2x",
          "23": "2x",
          "15": "2x",
          "41": "2x",
          "36": "2x",
          // bottom
          "40": "2x",
          "12": "2x",
          "38": "2x",
          "17": "2x",
          "9": "2x",
          // left
          "13": "2x",
          "42": "2x",
          "8": "2x",
          "19": "2x",
          "7": "2x",
          // right
          "5": "2x",
          "33": "2x",
          "4": "2x",
          "39": "2x",
          "14": "2x",
          // creative
          "3": "3x"
        }
      }
    };
  },
  computed: {
    ...mapState(["tasks"]),
    ...mapGetters(["tasksGrid"])
  },
  watch: {
    bingoType: function() {
      this.selectedCell = null;
    }
  },
  methods: {
    selectTask: function(task_id) {
      this.selectedCell = task_id;
      document.getElementById(`task_description_${task_id}`).scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
    },
    foldLegend: function() {
      this.showLegend = false;
    },
    unfoldLegend: function() {
      this.showLegend = true;
    },
    foldCommonRules: function() {
      this.showCommonRules = false;
    },
    unfoldCommonRules: function() {
      this.showCommonRules = true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.task_description {
  max-width: 100ch;
  font-size: small;
  padding: 0.5ch;
  display: grid;
  grid-template-columns: 4ch auto;
}

#tasks_grid .selected {
  box-shadow: 0 0 3px 2px rgba(25, 125, 220);
}

#tasks_description .selected {
  background-color: rgba(25, 125, 220, 0.2);
}

.score_2x {
  background-color: rgb(199, 225, 199);
}

.score_4x {
  background-color: rgb(147, 196, 125);
}

.score_3x {
  background-color: rgb(56, 118, 29);
}
</style>
