<template>
  <div class="block">
    <h3 class="header">
      <span>Проверка</span>
      <div class="link">
        <span v-if="show" @click="fold()">
          ↑
        </span>
        <span v-if="!show" @click="unfold()">
          ↓
        </span>
      </div>
    </h3>
    <form v-if="show" id="send_verified_log_form" @submit.prevent="send()">
      <div class="form_field">
        <div
          v-if="sendVerifiedLogStatus"
          id="status"
          :class="[sendVerifiedLogStatus]"
        >
          {{ getVerifiedLogStatusText(sendVerifiedLogStatus) }}
        </div>
        <label for="verification_main">Новый статус: </label>
        <select id="verification_main" v-model="status">
          <option
            v-for="statusName in ['unverified', 'verified', 'invalid']"
            :key="statusName"
            :value="statusName"
          >
            {{ getStatusText(statusName) }}
          </option>
        </select>
      </div>
      <div class="form_field">
        <input
          id="verification_congrats"
          v-model="hasCongratulations"
          type="checkbox"
        />
        <label for="verification_congrats">Поздравительный глас</label>
      </div>
      <div class="form_field">
        <textarea
          id="verification_comment"
          v-model="comment"
          rows="2"
          cols="40"
          placeholder="Комментарий для участника"
        >
        </textarea>
      </div>
      <button>Сохранить</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "VerificationForm",
  props: {
    result: { type: Object, required: true },
    bingoType: { type: String, required: true }
  },
  data: function() {
    return {
      show: true,
      sendVerifiedLogStatus: null,
      status: null,
      comment: null,
      hasCongratulations: false
    };
  },
  computed: {
    ...mapState(["session"])
  },
  methods: {
    getStatusText: function(status) {
      switch (status) {
        case "unverified":
          return "в проверке";
        case "verified":
          return "одобрено";
        case "invalid":
          return "неподходящий лог";
        default:
          return status;
      }
    },
    send: async function() {
      try {
        this.sendVerifiedLogStatus = "loading";
        await axios.post(
          `${process.env.VUE_APP_API_URL}/verify`,
          {
            participant: this.$store.state.bingo.participant,
            bingo_type: this.bingoType,
            task: this.result.task,
            status: this.status,
            comment: this.comment,
            has_congratulations: this.hasCongratulations,
            version: this.result.version
          },
          {
            auth: this.session
          }
        );
        this.sendVerifiedLogStatus = "success";
        await this.$store.dispatch("fetchResults");
      } catch (e) {
        console.log(`Failed to send verified log: ${e}`);
        this.sendVerifiedLogStatus = "failure";
      }
    },
    getVerifiedLogStatusText: function(status) {
      switch (status) {
        case "loading":
          return "Идёт отправка";
        case "success":
          return "Успешно обновлён";
        case "failure":
          return "Ошибка";
        default:
          return status;
      }
    },
    fold: function() {
      this.show = false;
    },
    unfold: function() {
      this.show = true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#send_verified_log_form {
  padding: 1ch;
}

#send_verified_log_form .form_field {
  padding: 0.5ch 0;
}

#send_verified_log_form label {
  padding: 0;
  margin-top: 0;
}

#send_verified_log_form button {
  padding: 1ch 1ch;
}
</style>
