<template>
  <div id="app">
    <Menu :menu-items="menuItems" />
    <router-view></router-view>
  </div>
</template>

<script>
import Menu from "./components/Menu.vue";

export const App = {
  name: "App",
  components: {
    Menu
  },
  data() {
    return {
      menuItems: [
        { id: "sailing", name: "Морское", link: "/results/sailing/" },
        { id: "dungeon", name: "Подземное", link: "/results/dungeon/" },
        { id: "form", name: "Отправить лог", link: "/send/" },
        { id: "rules", name: "Правила", link: "/rules/" }
      ]
    };
  },
  created() {
    this.$store.commit("initStateFromLocal");
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch("fetchResults"),
      this.$store.dispatch("fetchTasks")
    ]);
  }
};

export default App;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#app {
  font-family: Verdana, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text_link {
  text-decoration: underline;
}

.link:hover {
  color: #da251d;
  cursor: pointer;
}

.block h3 {
  margin-top: 0;
  font-size: 12pt;
}

.block {
  padding: 0.5em 0.5em;
  margin: 0.1em 0.1em;
  background: linear-gradient(
    to bottom,
    #f7fbfe,
    #e7e7e7 35px,
    #f9f9f9 35px,
    #f7f7f7 1px
  );
  border: 1px solid #ccc;
  border-radius: 6px;
}

.header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.results_grid {
  display: grid;
  grid-template-columns: repeat(7, 5ex);
  grid-gap: 0.5ex;
  padding: 1em 0;
  text-align: center;
}

.results_cell {
  border: black 1px solid;
  border-radius: 0.5ex;
  padding: 1ex;
}

.results_cell_description {
  padding: 1ex;
  grid-column: 2 / span 6;
  text-align: left;
  font-size: small;
}

#status {
  border: solid;
  border-radius: 5px;
  text-align: center;
}

#status.loading {
  border-color: rgba(25, 125, 220, 0.8);
  background-color: rgba(25, 125, 220, 0.2);
}
#status.success {
  border-color: rgba(10, 128, 10, 0.8);
  background-color: rgba(10, 128, 10, 0.2);
}
#status.failure {
  border-color: rgba(255, 0, 0, 0.8);
  background-color: rgba(255, 0, 0, 0.2);
}
.form_field {
  display: flex;
  flex-flow: row wrap;
  padding: 0.5em 0;
}

.badge {
  padding: 1px 4px;
  border: solid 1px;
  border-radius: 9px;
  font-size: smaller;
  font-weight: bold;
  text-decoration: none;
}

.score {
  color: rgb(10, 128, 10);
  border-color: rgb(10, 128, 10);
}
</style>
