<template>
  <div id="cell_tooltip" class="block">
    <h3>Детальное описание</h3>
    <div v-if="task">
      <div>
        <div class="field">Задание #{{ task.id }} ({{ task.short_name }}):</div>
        <div class="description">{{ task.description }}</div>
      </div>
      <div v-if="results" id="results">
        <p>
          <span class="field">Баллы:</span>
          <span class="badge">{{ getScore(results) }}</span>
        </p>
        <div>
          <div class="field">Присланные логи:</div>
          <ul>
            <li v-for="result in results" :key="result.version">
              <div class="log row">
                <a :href="result.log.log" target="_blank">
                  {{ result.log.log }}
                </a>
                <font-awesome-icon
                  v-if="result.has_congratulations"
                  :icon="['fas', 'birthday-cake']"
                />
                <span
                  :class="['badge', result.status]"
                  :title="getStatusText(result.status)"
                >
                  {{ result.score }}
                </span>
              </div>
              <div
                v-if="hasValidSession && result.checked_by"
                class="checked_by"
              >
                проверявший: {{ result.checked_by }}
              </div>
              <div v-if="result.log.jury_comment" class="row">
                <div class="field">Комментарии жюри:</div>
                <div class="description">{{ result.log.jury_comment }}</div>
              </div>
              <div
                v-if="hasValidSession && result.log.user_comment"
                class="row"
              >
                <div class="field">Комментарии участника:</div>
                <div class="description">{{ result.log.user_comment }}</div>
              </div>
              <VerificationForm
                v-if="hasValidSession"
                :result="result"
                :bingo-type="bingoType"
              ></VerificationForm>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else>Выберите ячейку</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VerificationForm from "@/components/VerificationForm";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBirthdayCake } from "@fortawesome/free-solid-svg-icons";

library.add(faBirthdayCake);

export default {
  name: "CellTooltip",
  components: { VerificationForm, FontAwesomeIcon },
  props: {
    task: { type: Object, required: false, default: null },
    bingoType: { type: String, required: false, default: null }
  },
  computed: {
    results: function() {
      return this.$store.state.results[this.bingoType].find(
        p => p.name === this.$store.state.bingo.participant
      ).results[this.task.id];
    },
    ...mapGetters(["tasksById", "hasValidSession"])
  },
  methods: {
    getStatusText: function(status) {
      switch (status) {
        case "unverified":
          return "в проверке";
        case "verified":
          return "одобрено";
        case "invalid":
          return "неподходящий лог";
        case null:
          return "нет логов";
        default:
          return status;
      }
    },
    getScore: function(results) {
      const lastVerified = results.find(r => r.status === "verified");
      return lastVerified ? lastVerified.score : 0;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#cell_tooltip {
  width: 50ch;
}
.field {
  font-weight: bolder;
}
.description {
  font-size: smaller;
}
.log {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.row {
  padding: 0.5ch 0 0.5ch 0;
}
.checked_by {
  font-size: smaller;
}
.unverified {
  color: grey;
}
.verified {
  color: green;
}
.invalid {
  color: red;
}
</style>
