<template>
  <router-link
    :to="`/results/${bingoType}/${participant.name}/`"
    class="link text_link"
    @click="$emit('participantSelected', participant.name)"
    >{{ participant.name }}</router-link
  >
  <div class="right-col">
    <span v-if="hasValidSession" class="badge unverified_logs">{{
      unverified_logs
    }}</span>
  </div>
  <div class="right-col">
    <span class="badge score">{{ participant.score }}</span>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "ParticipantsListItem",
  props: {
    bingoType: { type: String, required: true },
    participant: { type: Object, required: true }
  },
  emits: ["participantSelected"],
  computed: {
    unverified_logs: function() {
      const isUnverifiedResult = function(result) {
        return result.reduce(
          (acc, log) => {
            if (acc.seenUnverified || acc.seenVerified) return acc;
            if (log.status === "verified")
              return { seenVerified: true, seenUnverified: false };
            if (log.status === "unverified")
              return { seenVerified: false, seenUnverified: true };
            return acc;
          },
          { seenVerified: false, seenUnverified: false }
        ).seenUnverified;
      };
      return Object.values(this.participant.results).reduce(
        (acc, result) => acc + (isUnverifiedResult(result) ? 1 : 0),
        0
      );
    },
    ...mapState(["bingo/bingoType"]),
    ...mapGetters(["hasValidSession"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.unverified_logs {
  color: rgb(100, 100, 100);
  border-color: rgb(100, 100, 100);
}

.right-col {
  text-align: right;
}
</style>
