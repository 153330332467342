<template>
  <div id="all_participants" class="block">
    <h3 class="header">
      Участники
      <div class="link">
        <span v-if="show" @click="fold()">
          ↑
        </span>
        <span v-if="!show" @click="unfold()">
          ↓
        </span>
      </div>
    </h3>
    <p v-if="$store.state.results.loading">
      Загрузка списка участников
    </p>
    <div v-else-if="show">
      <div class="search">
        <input v-model="filter" type="text" placeholder="имя" />
        <font-awesome-icon
          :icon="['fas', 'sort-alpha-down']"
          :class="['link', ordering === 'name' ? 'active' : '']"
          @click="ordering = 'name'"
        />
        <font-awesome-icon
          :icon="['fas', 'sort-numeric-down-alt']"
          :class="['link', ordering === 'score' ? 'active' : '']"
          @click="ordering = 'score'"
        />
      </div>
      <div v-if="participants && participants.length" id="participants_list">
        <ParticipantsListItem
          v-for="participant in getOrderedParticipants(
            getFilteredParticipants(participants, filter),
            ordering
          )"
          :key="participant.name"
          :participant="participant"
          :bingo-type="bingoType"
          @participantSelected="$emit('participantSelected', $event)"
        />
      </div>
      <p v-else>
        Нет участников
      </p>
    </div>
  </div>
</template>

<script>
import ParticipantsListItem from "./ParticipantsListItem.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSortAlphaDown,
  faSortNumericDownAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faSortAlphaDown, faSortNumericDownAlt);

export default {
  name: "ParticipantsList",
  components: {
    ParticipantsListItem,
    FontAwesomeIcon
  },
  props: {
    participants: { type: Array, required: true },
    bingoType: { type: String, required: true }
  },
  emits: ["participantSelected"],
  data() {
    return {
      show: true,
      filter: "",
      ordering: "score"
    };
  },
  methods: {
    fold: function() {
      this.show = false;
    },
    unfold: function() {
      this.show = true;
    },
    getFilteredParticipants: function(participants, filter) {
      if (!filter) {
        return participants;
      }
      return participants.filter(p => p.name.toLowerCase().includes(filter));
    },
    getOrderedParticipants: function(participants, ordering) {
      let comparator = null;
      switch (ordering) {
        case "name":
          comparator = (one, another) => (one.name > another.name ? 1 : -1);
          break;
        case "score":
          comparator = (one, another) => (one.score < another.score ? 1 : -1);
          break;
      }

      if (!comparator) {
        return participants;
      }

      let result = [...participants];
      result.sort(comparator);
      return result;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#all_participants {
  width: 30ch;
}

#participants_list {
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;
  row-gap: 1ch;
  column-gap: 0.5ch;
  padding: 1ch 0 0.5ch 0;
}

.search {
  display: flex;
  flex-flow: row wrap;
}

.search .link {
  color: darkgray;
  padding-left: 1ch;
}
.search .link.active {
  color: black;
}
</style>
