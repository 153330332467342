import { createApp } from "vue";
import { createStore } from "vuex";
import * as VueRouter from "vue-router";
import App from "./App.vue";
import Bingo from "@/components/Bingo";
import Rules from "@/components/Rules";
import Form from "@/components/Form";
import Login from "@/components/Login";
import Welcome from "@/components/Welcome";
import ResultsTable from "@/components/ResultsTable";
import axios from "axios";

export const routes = [
  { path: "/", component: Welcome },
  { path: "/login", component: Login },
  {
    path: "/results/:bingoType",
    component: Bingo,
    props: true,
    children: [
      {
        path: "",
        component: ResultsTable,
        props: true
      },
      {
        path: ":name/",
        component: ResultsTable,
        props: true
      }
    ]
  },
  { path: "/rules", component: Rules },
  { path: "/rules/:bingoType", component: Rules, props: true },
  { path: "/send", component: Form, props: true }
];

const router = new VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(""),
  linkActiveClass: "active",
  routes
});

const bingoStore = {
  namespaced: true,
  state() {
    return {
      bingoType: null,
      participant: null
    };
  },
  mutations: {
    selectType(state, selected) {
      state.bingoType = selected;
    },
    selectParticipant(state, selected) {
      state.participant = selected;
    }
  }
};

const store = createStore({
  modules: {
    bingo: bingoStore
  },
  state() {
    return {
      tasks: {
        loading: true,
        dungeon: [],
        sailing: []
      },
      results: {
        loading: true,
        dungeon: [],
        sailing: []
      },
      session: {}
    };
  },
  getters: {
    hasValidSession: function(state) {
      return state.session && state.session.username && state.session.password;
    },
    tasksGrid: function(state) {
      return {
        dungeon: state.tasks.dungeon
          .map(e => e.tasks)
          .flat()
          .sort((e1, e2) => {
            return e1.order - e2.order;
          }),
        sailing: state.tasks.sailing
          .map(e => e.tasks)
          .flat()
          .sort((e1, e2) => {
            return e1.order - e2.order;
          })
      };
    },
    tasksById: function(state) {
      return {
        dungeon: new Map(
          state.tasks.dungeon
            .map(e => e.tasks)
            .flat()
            .map(el => [el.id, el])
        ),
        sailing: new Map(
          state.tasks.sailing
            .map(e => e.tasks)
            .flat()
            .map(el => [el.id, el])
        )
      };
    }
  },
  mutations: {
    reloadTasks(state, payload) {
      Object.assign(state.tasks, payload);
      state.tasks.loading = false;
    },
    reloadResults(state, payload) {
      Object.assign(state.results, payload);
      state.results.loading = false;
    },
    setResultsLoading(state, is_loading) {
      state.results.loading = is_loading;
    },
    setSession(state, session) {
      state.session.username = session.username;
      localStorage.setItem("username", session.username);
      state.session.password = session.password;
      localStorage.setItem("password", session.password);
    },
    resetSession(state) {
      state.session = {};
      localStorage.removeItem("username");
      localStorage.removeItem("password");
    },
    initStateFromLocal(state) {
      console.debug("initializing state from local storage");
      state.session.username = localStorage.getItem("username");
      state.session.password = localStorage.getItem("password");
    }
  },
  actions: {
    async fetchResults(context) {
      try {
        context.commit("setResultsLoading", true);
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/results`
        );
        context.commit("reloadResults", response.data);
      } catch (err) {
        console.log(err);
      }
    },
    async fetchTasks(context) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/tasks`
        );
        context.commit("reloadTasks", response.data);
      } catch (err) {
        console.log(err);
      }
    }
  }
});

let app = createApp(App);
app.use(router);
app.use(store);
app.mount("#root");
