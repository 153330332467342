<template>
  <div id="signin">
    <div
      v-if="loading !== null"
      id="status"
      :class="[loading ? 'loading' : 'success', errored ? 'failure' : '']"
    >
      {{ errored ? "Ошибка" : loading ? "Отправка" : "Успех" }}
    </div>
    <form v-if="!hasValidSession" @submit.prevent="logIn">
      <div class="form_field">
        <label for="username">Логин:</label>
        <input id="username" v-model="username" type="text" required />
      </div>
      <div class="form_field">
        <label for="username">Пароль:</label>
        <input id="password" v-model="password" type="password" required />
      </div>
      <div>
        <button id="submit" :disabled="loading">
          {{ loading ? "Отправляем..." : "Войти" }}
        </button>
      </div>
    </form>
    <div v-else>
      Вы залогинены как {{ session.username }}.
      <span class="link text_link" @click="logOut()">Выйти?</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import axios from "axios";

export default {
  name: "Login",
  data: function() {
    return {
      username: null,
      password: null,
      loading: null,
      errored: null
    };
  },
  computed: {
    ...mapState(["session"]),
    ...mapGetters(["hasValidSession"])
  },
  methods: {
    logIn: async function() {
      try {
        this.loading = true;
        const session = {
          username: this.username,
          password: this.password
        };
        // FIXME: disabled after finishing the contest
        // await axios.post(
        //   `${process.env.VUE_APP_API_URL}/verify`,
        //   {},
        //   {
        //     auth: session,
        //     validateStatus: function(status) {
        //       return status === 400 || status === 422;
        //     }
        //   }
        // );
        this.$store.commit("setSession", session);
        this.errored = false;
      } catch (e) {
        console.log(e);
        this.errored = true;
      } finally {
        this.loading = false;
      }
    },
    logOut: async function() {
      this.$store.commit("resetSession");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#signin {
  margin: 0 auto;
  width: 35ch;
}

.form_field {
  display: flex;
  flex-flow: row wrap;
  padding: 0.5em;
}
.form_field label {
  width: 10ch;
}
.form_field input {
  max-width: 100%;
}

#submit {
  margin: 0.5em 0;
  padding: 0.5em;
  width: 100%;
}
</style>
