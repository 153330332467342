<template>
  <div class="content">
    <div id="form" class="block">
      <h3>Отправить лог</h3>
      <div
        v-if="sendingStatus !== null"
        id="sending_status"
        :class="[sendingStatus]"
      >
        {{ getSendingStatusText(sendingStatus) }}
      </div>
      <form @submit.prevent="submitForm">
        <ul v-if="Object.entries(errors).length !== 0">
          <li v-for="error in errors" :key="error">
            {{ error }}
          </li>
        </ul>
        <div class="form_field">
          <label for="name">Имя божества:</label>
          <input
            id="name"
            v-model="name"
            type="text"
            tabindex="1"
            :class="[errors['name'] ? 'invalid' : '']"
          />
        </div>
        <div class="form_field">
          <label for="type">Бинго:</label>
          <select
            id="type"
            v-model="bingoType"
            tabindex="2"
            :class="[errors['bingoType'] ? 'invalid' : '']"
          >
            <option disabled value="">Выберите тип бинго</option>
            <option value="dungeon">Подземное</option>
            <option value="sailing">Морское</option>
          </select>
        </div>
        <div class="form_field">
          <label for="task">Задание:</label>
          <select
            id="task"
            v-model="task"
            tabindex="3"
            :class="[errors['task'] ? 'invalid' : '']"
          >
            <option disabled value="">Выберите задание</option>
            <option
              v-for="t in getFlattenedTasks(bingoType)"
              :key="t.id"
              :value="t.id"
            >
              {{ t.id }}: {{ t.short_name }}
            </option>
          </select>
        </div>
        <div v-if="chosenTask" class="description">
          {{ chosenTask.description }}
        </div>
        <div class="form_field">
          <label for="log">Лог:</label>
          <input
            id="log"
            v-model="log"
            type="text"
            tabindex="4"
            :class="[errors['log'] ? 'invalid' : '']"
          />
        </div>
        <div v-if="participantResult" class="description">
          Уже получили
          <a :href="participantResult.log.log">{{
            participantResult.log.log
          }}</a
          >,
          <span :class="`result_${participantResult.status}`">{{
            getStatusText(participantResult.status)
          }}</span>
        </div>
        <div class="form_field">
          <label for="comment">Комментарий:</label>
          <textarea id="comment" v-model="comment" tabindex="5" />
        </div>
        <button type="submit" tabindex="6">Отправить</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
const gvLinkRe = new RegExp(
  "^https://(godville.net|gv.erinome.net)/duels/log/\\w{4,10}.*$"
);

export default {
  name: "Form",
  data() {
    return {
      bingoType: null,
      task: null,
      name: null,
      log: null,
      comment: null,
      errors: {},
      sendingStatus: null
    };
  },
  computed: {
    chosenTask: function() {
      if (!this.bingoType || !this.task) {
        return null;
      }
      return this.tasksById[this.bingoType].get(this.task);
    },
    participantResult: function() {
      if (!this.bingoType || !this.name || !this.chosenTask) {
        return null;
      }

      const participant = this.$store.state.results[this.bingoType].find(
        p => p.name === this.name
      );
      return (
        participant &&
        participant.results[this.chosenTask.id] &&
        participant.results[this.chosenTask.id][0]
      );
    },
    ...mapState(["tasks"]),
    ...mapGetters(["tasksById"])
  },
  methods: {
    getFlattenedTasks: function(bingoType) {
      if (!bingoType || !this.tasks[bingoType]) {
        return [];
      }
      return this.tasks[bingoType].map(g => g.tasks).flat();
    },
    getStatusText: function(status) {
      switch (status) {
        case "verified":
          return "проверили и одобрили.";
        case "unverified":
          return "но пока не проверили.";
        case "invalid":
          return "но в нём найдены нарушения.";
      }
    },
    submitForm: async function(e) {
      this.errors = this.validateForm();
      if (Object.keys(this.errors).length) {
        return;
      }

      console.info("Sending form");
      this.sendingStatus = "loading";
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/send`,
          {
            name: this.name.trim(),
            bingo_type: this.bingoType,
            task: this.task,
            log: this.log.trim(),
            comment: this.comment && this.comment.trim()
          }
        );
      } catch (err) {
        console.log(err);
        this.sendingStatus = "failure";
        return;
      }
      this.sendingStatus = "success";
      this.$store.dispatch("fetchResults");
    },
    validateForm: function() {
      let errors = {};
      if (!this.name) {
        errors.name = "Введите имя божества";
      }
      if (!this.bingoType) {
        errors.bingoType = "Выберите тип бинго";
      }
      if (!this.task) {
        errors.task = "Выберите номер задания";
      }
      if (!this.log) {
        errors["log"] = "Не хватает лога";
      } else if (!this.validLog(this.log)) {
        errors["log"] = "Некорректная ссылка на лог";
      } else if (new Date() <= new Date("2021-09-11T21:00:00Z")) {
        errors["log"] = "Ещё календарь не перевернули";
      } else if (new Date() > new Date("2021-09-20T21:00:00Z")) {
        errors["log"] = "Время приёма логов окончилось";
      }
      return errors;
    },
    validLog: function(log) {
      if (!log) {
        return false;
      }
      return gvLinkRe.test(log.trim());
    },
    getSendingStatusText: function(sendingStatus) {
      switch (sendingStatus) {
        case "loading":
          return "Идёт отправка, подождите";
        case "success":
          return "Принято в обработку";
        case "failure":
          return "Ошибка отправки";
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#form {
  width: 40ch;
}

#form .form_field {
  padding: 0.5em 0;
}

#form button {
  padding: 0.5em 0;
  width: 100%;
}

.form_field input,
textarea,
select {
  min-width: 25ch;
}

.form_field label {
  min-width: 15ch;
  display: inline-block;
}

.description {
  font-size: smaller;
}

.result_verified {
  color: rgb(10, 128, 10);
}

.result_invalid {
  color: rgb(255, 0, 0);
}

.invalid {
  outline: rgba(255, 0, 0, 0.3) solid 2px;
}

#sending_status {
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
}
#sending_status.loading {
  border-color: rgba(25, 125, 220, 0.8);
  background-color: rgba(25, 125, 220, 0.2);
}
#sending_status.success {
  border-color: rgba(10, 128, 10, 0.8);
  background-color: rgba(10, 128, 10, 0.2);
}
#sending_status.failure {
  border-color: rgba(255, 0, 0, 0.8);
  background-color: rgba(255, 0, 0, 0.2);
}

#logs label {
  font-size: smaller;
}
</style>
