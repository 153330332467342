<template>
  <div id="results_table">
    <div class="block">
      <h3 class="header">
        <span>Таблица результатов</span>
        <span class="link" @click="refreshResults()">↻</span>
      </h3>
      <p v-if="$store.state.results.loading">Подождите, загружаем результаты</p>
      <div v-else-if="!name">Выберите пользователя</div>
      <div v-else>
        <div>
          <a :href="`https://godville.net/gods/${participant.name}`">{{
            participant.name
          }}</a>
        </div>
        <div>
          <span>Поздравительных гласов:</span>
          <span class="badge score">{{ participant.congratulations }}</span>
        </div>
        <div class="results_grid">
          <div
            v-for="task in tasksGrid[bingoType]"
            :key="task.id"
            :class="[
              'link',
              'text_link',
              'results_cell',
              `results_cell_${resultsGrid.get(task.id)[0].status || 'no_data'}`,
              selectedCell === task.id ? 'active' : ''
            ]"
            @click="selectCell(task.id)"
          >
            {{ getScore(task.id) }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="participant" class="block">
      <h3 class="header">
        <span>Легенда</span>
        <div class="link">
          <span v-if="showLegend" @click="fold()">
            ↑
          </span>
          <span v-if="!showLegend" @click="unfold()">
            ↓
          </span>
        </div>
      </h3>
      <div v-if="showLegend" class="results_grid results_legend">
        <div class="results_cell_description">
          Баллы начисляются за последний корректный лог.<br />
          Если последний присланный участником лог еще не был проверен или не
          подходит по условиям конкурса, этот лог не учитывается в подсчёте
          баллов, вместо него учитывается предыдущий.
        </div>
        <div class="results_cell results_cell_verified">1</div>
        <div class="results_cell_description">проверено</div>
        <div class="results_cell results_cell_unverified">0</div>
        <div class="results_cell_description">
          последний лог ещё не проверен
        </div>
        <div class="results_cell results_cell_invalid">0</div>
        <div class="results_cell_description">
          последний лог не отвечает условиям.
        </div>
        <div class="results_cell results_cell_no_data">0</div>
        <div class="results_cell_description">нет логов</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ResultsTable",
  props: {
    bingoType: { type: String, required: true },
    name: { type: String, required: false, default: null }
  },
  emits: ["cellSelected"],
  data() {
    return {
      showLegend: true,
      selectedCell: null
    };
  },
  computed: {
    participant: function() {
      if (this.$store.state.results.loading) {
        return null;
      }
      return this.$store.state.results[this.bingoType].find(
        p => p.name === this.name
      );
    },
    resultsGrid: function() {
      return new Map(
        this.tasksGrid[this.bingoType].map(t => [
          t.id,
          this.participant.results[t.id] || [
            { score: 0, status: null, task: t.id, log: null }
          ]
        ])
      );
    },
    ...mapGetters(["tasksGrid"])
  },
  watch: {
    bingoType: function() {
      this.reset();
    },
    name: function() {
      this.reset();
    }
  },
  created() {
    this.$store.commit("bingo/selectParticipant", this.name);
  },
  methods: {
    fold: function() {
      this.showLegend = false;
    },
    unfold: function() {
      this.showLegend = true;
    },
    selectCell: function(task_id) {
      this.$emit("cellSelected", task_id);
      this.selectedCell = task_id;
    },
    reset: function() {
      this.selectedCell = null;
    },
    refreshResults: async function() {
      this.$store.dispatch("fetchResults");
    },
    getScore: function(task_id) {
      const lastVerified = this.resultsGrid
        .get(task_id)
        .find(r => r.status === "verified");
      return lastVerified ? lastVerified.score : 0;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#results_table {
  text-align: center;
  min-width: 35ch;
}

.results_cell_verified {
  background-color: rgba(10, 250, 10, 0.5);
}

.results_cell_unverified {
  background-color: rgba(100, 100, 100, 0.5);
}

.results_cell_invalid {
  background: rgba(250, 10, 10, 0.5);
}
.results_cell.active {
  box-shadow: 0 0 2px 3px rgba(25, 125, 220, 0.5);
}
</style>
