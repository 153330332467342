<template>
  <div id="bingo" class="content">
    <ParticipantsList
      :participants="participants"
      :bingo-type="bingoType"
      @participantSelected="selectParticipant"
    />
    <router-view @cellSelected="cellSelected" />
    <CellTooltip
      :bingo-type="bingoType"
      :task="tasksById[bingoType].get(selectedTask)"
    />
  </div>
</template>

<script>
import ParticipantsList from "./ParticipantsList.vue";
import CellTooltip from "./CellTooltip.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Bingo",
  components: {
    ParticipantsList,
    CellTooltip
  },
  props: {
    bingoType: { type: String, required: true }
  },
  data: function() {
    return {
      selectedTask: null
    };
  },
  computed: {
    participants: function() {
      return this.$store.state.results[this.bingoType];
    },
    ...mapGetters(["tasksById"])
  },
  watch: {
    bingoType: function() {
      this.resetCell();
    }
  },
  created() {
    this.$store.commit("bingo/selectType", this.bingoType);
  },
  methods: {
    cellSelected: function(selectedCell) {
      this.selectedTask = selectedCell;
    },
    selectParticipant: function(name) {
      this.resetCell();
      this.$store.commit("bingo/selectParticipant", name);
    },
    resetCell: function() {
      this.selectedTask = null;
    }
  }
};
</script>

<style scoped></style>
