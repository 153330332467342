<template>
  <div class="menu">
    <div
      v-for="(menuItem, index) in menuItems"
      :key="`menu-${index}`"
      class="menu_item"
    >
      <router-link :to="menuItem.link" class="menu_link">
        {{ menuItem.name }}
      </router-link>
      <span v-if="index !== menuItems.length - 1" class="separator">|</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    menuItems: {
      type: Array,
      required: true
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0 0 1em 0;
}
.menu_item {
  display: inline;
}
.menu_link {
  color: #199bdc;
  font-size: larger;
  text-decoration: underline;
}
.menu_link:hover {
  color: #da251d;
  cursor: pointer;
}
.active {
  color: unset;
  text-decoration: none;
}
.separator {
  padding: 0 0.5em;
}
</style>
