<template>
  <div id="greeting">
    <p>Добро пожаловать на ежегодное шахтёрское Бинго!</p>
    <img src="@/assets/newlogo.svg" alt="логотип шахтёрского бинго" />
  </div>
</template>
<script>
export default {
  name: "Welcome"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#greeting {
  text-align: center;
}

#greeting img {
  width: 300px;
  max-width: 100%;
}
</style>
